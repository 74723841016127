import React from 'react';
import './ShippingAndDelivery.scss';
import NavbarHomePage from '../HomePageComponent/NavbarHomePage';
import FooterHomePage from '../HomePageComponent/FooterHomePage';

const ShippingAndDelivery = () => {
    return (
        <>
            <NavbarHomePage />
            <div className="shipping-delivery">
                <section className="shipping-delivery__hero">
                    <h1 className="shipping-delivery__hero-title">Shipping & Delivery Information</h1>
                    <p className="shipping-delivery__hero-subtitle">
                        Please note that all shipping-related queries and processes are handled by individual sellers on our platform. Prodinent Digital Ventures does not sell products directly, and shipping policies vary by seller.
                    </p>
                </section>

                <section className="shipping-delivery__content">
                    <div className="shipping-delivery__section">
                        <h2 className="shipping-delivery__section-title">Order Processing and Shipping Time</h2>
                        <p className="shipping-delivery__section-description">
                            Each seller on our platform has their own order processing times. Typically, orders are processed within 1-3 business days. However, processing times may vary depending on the product or seller's policies.
                            Once your order is processed, shipping times will depend on the seller's shipping carrier and your location. Please contact the seller for more information on the specific shipping timeframe for your order.
                        </p>
                    </div>

                    <div className="shipping-delivery__section">
                        <h2 className="shipping-delivery__section-title">Shipping Costs</h2>
                        <p className="shipping-delivery__section-description">
                            Shipping costs are determined by individual sellers. Some sellers may offer free shipping, while others may charge a shipping fee depending on your location, the size of your order, and the chosen delivery method. 
                            Please review the shipping policies of the seller during checkout to confirm shipping charges.
                        </p>
                    </div>

                    <div className="shipping-delivery__section">
                        <h2 className="shipping-delivery__section-title">International Shipping</h2>
                        <p className="shipping-delivery__section-description">
                            If a seller offers international shipping, it will be indicated on the product page or at checkout. International shipping may incur additional charges such as customs fees, taxes, or duties, which are the buyer's responsibility.
                            Please review the seller's international shipping policy and contact them directly for any inquiries about shipping to your country.
                        </p>
                    </div>
                </section>

                <section className="shipping-delivery__cta">
                    <p className="shipping-delivery__cta-text">
                        Have questions about your order? Please reach out to the seller directly using the contact information provided in your order details.
                    </p>
                </section>
            </div>
            <FooterHomePage />
        </>
    );
};

export default ShippingAndDelivery;
