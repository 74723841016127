import React from 'react';
import './PrivacyPolicy.scss';
import FooterHomePage from '../HomePageComponent/FooterHomePage';
import NavbarHomePage from '../HomePageComponent/NavbarHomePage';

const PrivacyPolicy = () => {
  return (
    <>
      <NavbarHomePage />
      
      <div className="privacy-policy">
        <h1 className="privacy-policy__title">Privacy Policy</h1>
        <div className="privacy-policy__content">
          <h2>Introduction</h2>
          <p>
            This policy (“Privacy Policy”) is to inform you about what kinds of information we collect about you when you purchase something from our store, including personally identifying information, how we use it, with whom it is shared, and the choices you have regarding our use of that information when you use or interact with our website, www.prodinent.com. At Prodinent, protecting your private information is our priority and we are committed to being upfront about it. We respect the privacy needs and concerns of our customers. We appreciate the trust you place in us when you choose to make use of our website and services and we take that responsibility seriously. By “personally identifying information”, we mean information that directly identifies you, such as your name, delivery address, phone number, payment, credit and billing information, and e-mail addresses. We also collect demographic information, which is not unique to you, such as your postcode. To clarify, the given meaning of “Personal information” under this policy is in addition to the definition provided by the applicable law.
          </p>
          
          <h2>Changes to this Privacy Policy</h2>
          <p>
            We reserve the right to make changes to this Policy at any given time. If you want to make sure that you are up to date with the latest changes, we advise you to frequently visit this page. If at any point in time, we decide to make use of any personally identifiable information on file, in a manner vastly different from that which was stated when this information was initially collected, the user or users shall be promptly notified by email. Users at that time shall have the option as to whether to permit the use of their information in this separate manner.
          </p>
          
          <h2>Privacy Policy for Children</h2>
          <p>
            We do not intentionally gather Personal Data from users who are under the age of 13. If a user under the age of 13 submits Personal Data to us and we learn that Personal Data is the information of a user under the age of 13, we will attempt to delete the information as soon as possible. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us. We will delete such information from our files as soon as reasonably possible.
          </p>
          
          <h2>How We Obtain or Collect Information about You</h2>
          <p>
            <strong>Information You Provide:</strong> “Personal Data" means any information which, either alone or in combination with other information we hold about you, identifies you as an individual, including, but not limited to your: Name, telephone number, location information, city, email address, and username.
          </p>
          
          <p>
            <strong>Automated Information Collection:</strong> We may collect certain information about your use of the website through the use of tracking technologies or by other passive means. This “passively collected” information includes, but is not limited to, the domain name of the website that allowed you to navigate to the Site, search engines used, and the date and time you visited.
          </p>
          
          <h2>Personal Information That We Share</h2>
          <p>
            We do not sell, rent, or otherwise trade personal information collected about you through the Site, from Log-In Users, or through Offline Tracking Information collected through the Services to others. The information that you provide to us is an important part of our business and we share your personal information only as described below:
          </p>
          <ul>
            <li><strong>Third-Party Service Providers:</strong> We may work with third parties to make available to you products and services for use or employ other companies and individuals to perform certain functions on our behalf. Such services may include product tracking, product shipping, and delivery, API integration to perform some operations such as integrating third-party services for SMS, WhatsApp notifications, etc.</li>
            <li><strong>Protection:</strong> We may disclose or transfer personal information or any information collected through the Site and your usage of our Service if we deem it necessary to protect our rights, property, safety, or that of our users, or third parties.</li>
          </ul>
        </div>
      </div>

      <FooterHomePage />
    </>
  );
};

export default PrivacyPolicy;
