import React from 'react';
import './AboutUs.scss';
import NavbarHomePage from '../HomePageComponent/NavbarHomePage';
import FooterHomePage from '../HomePageComponent/FooterHomePage';

const AboutUs = () => {
    return (
        <>
            <NavbarHomePage />
            <div className="about-us">
                <section className="about-us__hero">
                    <h1 className="about-us__title">About Prodinent Digital Ventures</h1>
                    <p className="about-us__subtitle">
                        Empowering small-scale online sellers to build their digital stores with ease.
                    </p>
                </section>

                <section className="about-us__content">
                    <div className="about-us__block about-us__block--mission">
                        <h2 className="about-us__block-title">Our Mission</h2>
                        <p className="about-us__block-text">
                            Prodinent Digital Ventures aims to simplify the process of setting up an online store for small-scale sellers.
                            Our platform offers tools to manage orders, handle logistics, and customize online storefronts effortlessly.
                        </p>
                    </div>

                    <div className="about-us__block about-us__block--vision">
                        <h2 className="about-us__block-title">Our Vision</h2>
                        <p className="about-us__block-text">
                            We envision a world where every small business can access powerful e-commerce tools and grow without the technical overhead.
                        </p>
                    </div>

                    <div className="about-us__block about-us__block--values">
                        <h2 className="about-us__block-title">Our Values</h2>
                        <ul className="about-us__list">
                            <li className="about-us__list-item">Empowerment</li>
                            <li className="about-us__list-item">Simplicity</li>
                            <li className="about-us__list-item">Growth</li>
                            <li className="about-us__list-item">Customer Success</li>
                        </ul>
                    </div>
                </section>

                <section className="about-us__cta">
                    <p className="about-us__cta-text">
                        Ready to grow your online business? Join Prodinent Digital Ventures today!
                    </p>
                    <a className="about-us__cta-button" href="/contact">
                        Contact Us
                    </a>
                </section>
            </div>
            <FooterHomePage/>
        </>
    );
};

export default AboutUs;
