import React,{ createContext,useState } from "react";

export const SidebarInactiveContext = createContext(null)

function Inactive({children}){

    //checking the screen size to display side bar or not
    let screenWidth = 0;
    let value=true
    screenWidth = window.innerWidth;
    
    if (screenWidth > 900) {
        value=false
        
    }
    if (screenWidth < 900) {
        value=true
        
    }
    const [inactive,setInactive]=useState(value)
    // console.log("context",value);
    

    return(
        <SidebarInactiveContext.Provider value={{inactive,setInactive}}>
            {children}
        </SidebarInactiveContext.Provider>
    )
}
export default Inactive