import React, { useState } from 'react';
import './ContactUs.scss';
import NavbarHomePage from '../HomePageComponent/NavbarHomePage';
import FooterHomePage from '../HomePageComponent/FooterHomePage';
import { publicRequest } from '../../../requestMethods';

const ContactUs = () => {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await publicRequest.post('/notification/contactus', formData, {
                headers: { 'Content-Type': 'application/json' },
            });
            console.log(response.data);
            setSuccessMessage("Your message has been sent successfully!");
            setFormData({ name: '', email: '', message: '' });

        } catch (error) {
            setErrorMessage('An error occurred. Please try again later.');
        }
    };

    return (
        <>
            <NavbarHomePage />
            <div className="contact-us">
                <section className="contact-us__hero">
                    <h1 className="contact-us__title">Contact Us</h1>
                    <p className="contact-us__subtitle">
                        We'd love to hear from you. Reach out to us for any queries or support.
                    </p>
                </section>

                <section className="contact-us__details">
                    <p>Email: <a href="mailto:hi@prodinent.com">hi@prodinent.com</a></p>
                    <p>Operating Address: Hyder Manzil Velupadam Post, Thrissur, Kerala, 680303</p>
                </section>

                <form className="contact-us__form" onSubmit={handleSubmit}>
                    <div className="contact-us__input-group">
                        <label className="contact-us__input-group-label" htmlFor="name">Name</label>
                        <input
                            className="contact-us__input-group-input"
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder="Your Name"
                            required
                        />
                    </div>

                    <div className="contact-us__input-group">
                        <label className="contact-us__input-group-label" htmlFor="email">Email</label>
                        <input
                            className="contact-us__input-group-input"
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder="Your Email"
                            required
                        />
                    </div>

                    <div className="contact-us__input-group">
                        <label className="contact-us__input-group-label" htmlFor="message">Message</label>
                        <textarea
                            className="contact-us__input-group-textarea"
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleInputChange}
                            placeholder="Your Message"
                            required
                        />
                    </div>

                    <button className="contact-us__button" type="submit">Submit</button>
                </form>

                {successMessage && <p className="contact-us__success">{successMessage}</p>}
                {errorMessage && <p className="contact-us__error">{errorMessage}</p>}

                <section className="contact-us__cta">
                    <p className="contact-us__cta-text">
                        Have more questions? Get in touch today!
                    </p>
                    <a className="contact-us__cta-button" href="/about">
                        Learn More About Us
                    </a>
                </section>
            </div>
            <FooterHomePage />
        </>
    );
};

export default ContactUs;
