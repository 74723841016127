    import React, { useState } from 'react';
    import { useNavigate, useParams } from 'react-router-dom';
    import './changePassword.scss';
    import { ToastContainer, toast } from 'react-toastify';
    import 'react-toastify/dist/ReactToastify.css';
    import { Bars } from 'react-loader-spinner';
    import { publicRequest } from '../../requestMethods';

    const ChangePassword = () => {
        const navigate = useNavigate();
        const { token } = useParams();
        const [password, setPassword] = useState('');
        const [confirmPassword, setConfirmPassword] = useState('');
        const [loading, setLoading] = useState(false);
        const [errorMessage, setErrorMessage] = useState('');

        const handleSubmit = async (e) => {
            e.preventDefault();
            // console.log("Token",token);
            if (password !== confirmPassword) {
                setErrorMessage('Passwords do not match.');
                return;
            }

            setLoading(true);
            try {
                const response = await publicRequest.post(`/auth/reset-password/${token}`, { password });
                toast.success('Password has been reset successfully.');
                setLoading(false);
                navigate('/login');
            } catch (error) {
                setErrorMessage('Failed to reset password. Please try again.');
                setLoading(false);
            }
        };

        return (
            <div className='change-password'>
                <div className='change-password__container'>
                    <div className='change-password__content'>
                        <h2 className='change-password__title'>Change Password</h2>
                        <form className='change-password__form' onSubmit={handleSubmit}>
                            <div className='change-password__form-group'>
                                <label htmlFor='password' className='change-password__label'>New Password</label>
                                <input
                                    type='password'
                                    id='password'
                                    className='change-password__input'
                                    placeholder='Enter your new password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='change-password__form-group'>
                                <label htmlFor='confirmPassword' className='change-password__label'>Confirm Password</label>
                                <input
                                    type='password'
                                    id='confirmPassword'
                                    className='change-password__input'
                                    placeholder='Confirm your new password'
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <button type='submit' className='change-password__button'>
                                {loading ? (
                                    <Bars
                                        heigth='30'
                                        width='30'
                                        color='#0b2b66'
                                        ariaLabel='loading'
                                        className='change-password__loader'
                                    />
                                ) : (
                                    'Change Password'
                                )}
                            </button>
                        </form>
                        {errorMessage && (
                            <div className='change-password__error-message'>
                                {errorMessage}
                            </div>
                        )}
                        <ToastContainer />
                    </div>
                </div>
            </div>
        );
    };

    export default ChangePassword;
