import './navbar.scss'

const Navbar = () => {



  
    return (
        < div className='navbar-seller'>
           
               
                < div className="navbar-seller__user">
                    {/* <img src="https://www.tikktap.com/Graphics/Handwave-2.svg" alt="Hand Wave Image" /> */}
                    {/* <span><em>Hi</em> Admin<em>!</em></span> */}
                    <h3>Prodinent</h3>
                   
                </div>
            
        </div >
    )
}

export default Navbar