import jsPDF from 'jspdf';
import 'jspdf-autotable';
import QRCode from 'qrcode';
import JsBarcode from 'jsbarcode';

const createBarcode = (text) => {
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, text, {
    format: 'CODE128', // Barcode format
    width: 2,          // Line thickness
    height: 40,        // Barcode height
    displayValue: false // Display barcode value below
  });
  return canvas.toDataURL('image/png');
};


export const generateShippingLabel = async (order, customerData, warehouseData, sellerData) => {
  const doc = new jsPDF();
  // console.log("order", order);
  console.log("WH data", warehouseData);

  // Set the page margins and usable width
  const margin = 10;
  const labelWidth = 190; // Width within A4 page (210mm - 2*margin)

  // Define fixed variables for positioning
  const shippingLine = 52;
  const shippingLineGap = 8;
  const sellerSectionGap = 8;   // Gap between seller info lines

  // Outer border (adjusted to prevent overflow)
  doc.setLineWidth(1);
  doc.rect(margin, margin, labelWidth, 270); // (x, y, width, height)

  // Logo section
  const logoUrl = 'https://ucarecdn.com/39bf55fc-6f5a-4411-bfda-b8aa4089bee9/-/preview/500x500/';
  // const logoUrl = 'https://firebasestorage.googleapis.com/v0/b/prodinent-fc68e.appspot.com/o/Logo%2FProdinent%20Logo.png?alt=media&token=7ddbdf88-2efd-431c-8317-6401fb77fc8f';
  doc.addImage(logoUrl, 'jpg', margin + 4, 12, 45, 30); // Adjusted to fit nicely within the label


  doc.line(margin + 55, 10, margin + 55, 45); // Vertical line to separate logo from other info

  // Barcode Section
  const barcodeDataUrl = createBarcode(order.orderID);
  doc.addImage(barcodeDataUrl, 'PNG', margin + 80, 12.5, 80, 30);


  // Separator Line
  doc.line(margin, 45, margin + labelWidth, 45);

  // Shipping Address
  doc.setFontSize(14);
  doc.text('Ship To:', margin + 4, shippingLine);
  doc.setFont(undefined, 'bold');
  doc.setFontSize(24);
  doc.text(customerData.name, margin + 4, shippingLine + 9);
  doc.setFontSize(20);
  doc.setFont('Arial', 'normal');
  doc.text(order.shippingAddress.fullAddress, margin + 5, shippingLine + 9 + shippingLineGap);
  doc.text(`${order.shippingAddress.city}`, margin + 5, shippingLine + 9 + 2 * shippingLineGap);
  doc.text(`${order.shippingAddress.state}`, margin + 5, shippingLine + 9 + 3 * shippingLineGap);
  doc.text(`${order.shippingAddress.pincode}`, margin + 5, shippingLine + 9 + 4 * shippingLineGap);
  doc.text(order.shippingAddress.country, margin + 5, shippingLine + 9 + 5 * shippingLineGap);
  doc.text('Mobile: ' + customerData.phone, margin + 5, shippingLine + 9 + 6 * shippingLineGap);


  const verticalLineX = margin + 140; // X coordinate for the vertical line
  const verticalLineStartY = 45; // Start a little above "Ship To:"
  const verticalLineEndY = shippingLine + 8 + 7 * shippingLineGap;

  doc.line(verticalLineX, verticalLineStartY, verticalLineX, verticalLineEndY);

  // Order ID and Date (Placed within the separated section)
  const dateOptions = { day: '2-digit', month: 'long', year: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(order.createdAt));

  const orderInfoX = verticalLineX + 2;
  doc.setFontSize(12);
  doc.setFont(undefined, 'bold');
  doc.text('Order ID: ' + order.orderID, orderInfoX, shippingLine + 25);
  doc.text('Date: ' + formattedDate, orderInfoX, shippingLine + 25 + shippingLineGap);
  doc.setFont(undefined, 'normal');



  // Separator Line
  const shippingSectionEnd = shippingLine + 8 + 7 * shippingLineGap;
  doc.line(margin, shippingSectionEnd, margin + labelWidth, shippingSectionEnd);

  // Seller Section
  const sellerSectionTop = shippingSectionEnd + 10; // Start position for the seller section
  doc.setFontSize(18);
  doc.setFont(undefined, 'bold');
  doc.text('Seller: ' + sellerData.sellerName, margin + 4, sellerSectionTop);
  doc.setFontSize(17);
  doc.setFont(undefined, 'normal');
  const sellerAddress = [
    warehouseData.name + ',' + warehouseData.address,
    `${warehouseData.city}, ${warehouseData.state || ''}, ${warehouseData.pin}, ${warehouseData.country}`
  ];
  sellerAddress.forEach((line, index) => {
    doc.text(line, margin + 4, sellerSectionTop + 10 + index * sellerSectionGap);
  });

  // Separator Line
  const sellerSectionEnd = sellerSectionTop + 10 + sellerAddress.length * sellerSectionGap + 10;
  doc.line(margin, sellerSectionEnd, margin + labelWidth, sellerSectionEnd);

  // Product Details Section
  const productTableStartY = sellerSectionEnd + 10;
  const productData = order.products.map(product => [
    product.productName.length > 20 ? product.productName.substring(0, 20) + '...' : product.productName,
    product.qty,
    `INR ${(product.unitPrice * product.qty).toFixed(2)}`
  ]);

  const maxTableHeight = 270 - productTableStartY - 60; // Reserve space for return address and QR code

  doc.autoTable({
    startY: productTableStartY,
    head: [['Product', 'Qty', 'Total']],
    body: productData,
    theme: 'striped',
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 10 },
    styles: { fontSize: 8, cellPadding: 2, textColor: [0, 0, 0] },
    margin: { left: margin + 5, right: margin + 5 },
    tableWidth: 'auto',
    maxHeight: maxTableHeight,
  });

  // Total Amount
  const totalAmount = order.products.reduce((acc, product) => acc + (product.unitPrice * product.qty), 0).toFixed(2);
  doc.setFontSize(10);
  doc.setFont(undefined, 'bold');
  doc.text('Total: INR ' + totalAmount, margin + 120, doc.previousAutoTable.finalY + 5);

  // Separator Line
  const separatorY = doc.previousAutoTable.finalY + 10;
  doc.line(margin, separatorY, margin + labelWidth, separatorY);

  // Return Address Section
  const returnAddressStartY = separatorY + 5;
  doc.setFontSize(16);
  doc.setFont(undefined, 'normal');
  // doc.text('Return Address:', margin + 4, returnAddressStartY);

  // Define the lines for the return address
  const returnAddressText = [
    warehouseData.return_address,
    `${warehouseData.return_city}, ${warehouseData.return_state}, ${warehouseData.return_pin}`,
    warehouseData.return_country,
    warehouseData.return_phone
  ];

  // Set font size and line height
  const lineHeight = 7;
  const startY = returnAddressStartY + 5;
  const textWidth = labelWidth - 70; // Adjusted to make room for QR code

  // Add each line with proper spacing
  let currentY = startY;
  returnAddressText.forEach(line => {
    const splitText = doc.splitTextToSize(line, textWidth);
    splitText.forEach(textLine => {
      doc.text(textLine, margin + 4, currentY);
      currentY += lineHeight;
    });
  });

  // QR Code
  const sellerURL = `https://prodinent.com/${sellerData.sellerID}`;
  const qrCodeDataUrl = await QRCode.toDataURL(sellerURL, { width: 100, margin: 1 });

  doc.addImage(qrCodeDataUrl, 'PNG', margin + labelWidth - 40, returnAddressStartY, 30, 30);

  // Save the PDF
  doc.save('Shipping_Label_Order_' + order.orderID + '.pdf');
};