import React, { useState, useEffect } from 'react';
import './paymentSettingsForm.scss';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { sellerRequest } from '../../../requestMethods';
import { useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom';

const PaymentSettingsForm = () => {
    const { sellerID } = useSelector((state) => state.seller.sellerData);
    const [loading, setLoading] = useState(true);
    // Initial state matches the PaymentSettingsSchema structure
    const [paymentSettings, setPaymentSettings] = useState({
        manualPaymentEnabled: {
            isEnabled: false,
            deliveryCharge: 0,
            waiveThreshold: 0,
        },
        COD: {
            isEnabled: false,
            deliveryCharge: 0,
            waiveThreshold: 0,
        },
        razorpay: {
            isEnabled: false,
            deliveryCharge: 0,
            waiveThreshold: 0,
        },
    });

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const location = useLocation();

    // Fetch payment settings from the backend
    useEffect(() => {
        const fetchPaymentSettings = async () => {
            try {
                const res = await sellerRequest.get(`/seller/${sellerID}/paymentSettings`);
                console.log("Res PaymentSettings ", res.data);

                // Set the fetched data to state
                setPaymentSettings(res.data);
                setLoading(false)
            } catch (error) {
                console.error('Error fetching payment settings:', error);
            }
        };
        fetchPaymentSettings();

        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');
        const error = searchParams.get('error');

        if (code) {
            handleRazorpaySuccess(code);
        } else if (error) {
            handleRazorpayError(error);
        }
    }, [sellerID, location]);

    const handleInputChange = (e, section) => {
        const { name, value, type, checked } = e.target;
        setPaymentSettings((prevSettings) => ({
            ...prevSettings,
            [section]: {
                ...prevSettings[section],
                [name]: type === 'checkbox' ? checked : parseFloat(value),
            },
        }));
    };

    const handleCheckboxChange = (section, isEnabled) => {
        setPaymentSettings((prevSettings) => ({
            ...prevSettings,
            [section]: {
                ...prevSettings[section],
                isEnabled: isEnabled,
            },
        }));
    };
    const handleManualPaymentChange = (e) => {
        const { name, value, type, checked } = e.target;
        setPaymentSettings((prevSettings) => ({
            ...prevSettings,
            manualPaymentEnabled: {
                ...prevSettings.manualPaymentEnabled,
                [name]: type === 'checkbox' ? checked : value,
            }
        }));
    };

    const handleCODChange = (e) => {
        const { name, value, type, checked } = e.target;
        setPaymentSettings((prevSettings) => ({
            ...prevSettings,
            COD: {
                ...prevSettings.COD,
                [name]: type === 'checkbox' ? checked : value,
            }
        }));
    };

    const handleRazorpayChange = (e) => {
        const { name, value, type, checked } = e.target;
        setPaymentSettings((prevSettings) => ({
            ...prevSettings,
            razorpay: {
                ...prevSettings.razorpay,
                [name]: type === 'checkbox' ? checked : value,
            }
        }));
    };

    // Save payment settings to backend
    const handleSaveSettings = async () => {
        setLoading(true)
        try {
            await sellerRequest.put(`/seller/${sellerID}/paymentSettings`, paymentSettings);
            setSnackbarMessage('Payment settings updated successfully!');
            setSnackbarSeverity('success');
        } catch (error) {
            console.log(error);

            setSnackbarMessage('Failed to update payment settings.');
            setSnackbarSeverity('error');
        } finally {
            setSnackbarOpen(true);
            setLoading(false)
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const handleRazorpaySuccess = async (code) => {
        try {
            setLoading(true);

            const res = await sellerRequest.post(`/payment/razorpay-connect`, { code, sellerID });
            console.log("Res Razorpay ", res.data);
            // Send the code to your backend to exchange for tokens
            setSnackbarMessage('Razorpay account connected successfully!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            // Optionally, refresh payment settings here
        } catch (error) {
            console.error('Error connecting Razorpay account:', error);
            setSnackbarMessage('Failed to connect Razorpay account. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleRazorpayError = (error) => {
        console.error('Razorpay authorization error:', error);
        setSnackbarMessage('Failed to connect Razorpay account. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
    };
    const handleConnectRazorpay = async () => {
        try {
            setLoading(true);
            // Construct the Razorpay authorization URL
            const clientId = 'OzRQZbHgAZOZIr';  //test
            // const clientId = 'OzRQZtcW5zvLDe'; //production
            const redirectUri = encodeURIComponent('http://localhost:3000/settings/paymentSettings');
            const scope = 'read_only';
            const state = 'current_state'; // Implement this function to generate a random state

            const authUrl = `https://auth.razorpay.com/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
            window.location.href = authUrl;


        } catch (error) {
            console.error('Error initiating Razorpay authorization:', error);
            setSnackbarMessage('Failed to connect to Razorpay. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };
    const handlePayNow = async () => {

        var options = {
            key: "rzp_live_oauth_P0hgI0RWvUvbx8",
            amount: "29900",
            name: "Prodinent",
            description: "Test Transaction",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/135px-Apple_logo_black.svg.png",
            // order_id: "order_9A33XWu170gUtm",
            handler: async function (response) {
                alert(response.razorpay_payment_id);
            },
            prefill: {
                name: "Gaurav Kumar",
                email: "gaurav.kumar@example.com"
            },
            notes: {
                address: "note value"
            },
            theme: {
                color: "#F37254"
            }
        };
        const razorpay = new window.Razorpay(options);
        razorpay.open();
        razorpay.on("payment.failed", async function (response) {
            console.log("Payment Failed", response);
        });
    }

    return (
        <div className="payment-settings-form">
            {loading && (
                <Backdrop
                    open
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <h3 className="payment-settings-form__title">Payment Settings</h3>

            {/* Manual Payment */}
            <div className="payment-settings-form__section">
                <h4 className="payment-settings-form__section-title">Manual Payment</h4>
                <div className="payment-settings-form__field">
                    <input
                        type="checkbox"
                        id="manualPaymentEnabled"
                        checked={paymentSettings.manualPaymentEnabled.isEnabled}
                        onChange={(e) => handleCheckboxChange('manualPaymentEnabled', e.target.checked)}
                        className="payment-settings-form__checkbox"
                    />
                    <label htmlFor="manualPaymentEnabled" className="payment-settings-form__label">
                        Enable Manual Payment (Handle Payment directly with buyer)
                    </label>
                </div>

                {paymentSettings.manualPaymentEnabled.isEnabled && (
                    <div className="payment-settings-form__field-group">
                        <div className="payment-settings-form__field">
                            <label htmlFor="manualPaymentDeliveryCharge" className="payment-settings-form__label">
                                Delivery Charge:
                            </label>
                            <input
                                type="number"
                                id="manualPaymentDeliveryCharge"
                                name="deliveryCharge"
                                value={paymentSettings.manualPaymentEnabled.deliveryCharge}
                                onChange={(e) => handleInputChange(e, 'manualPaymentEnabled')}
                                className="payment-settings-form__input"
                            />
                        </div>

                        <div className="payment-settings-form__field">
                            <label htmlFor="manualPaymentWaiveThreshold" className="payment-settings-form__label">
                                Waive Delivery Charge If Order Value Above:
                            </label>
                            <input
                                type="number"
                                id="manualPaymentWaiveThreshold"
                                name="waiveThreshold"
                                value={paymentSettings.manualPaymentEnabled.waiveThreshold}
                                onChange={(e) => handleInputChange(e, 'manualPaymentEnabled')}
                                className="payment-settings-form__input"
                            />
                        </div>
                    </div>
                )}
            </div>


            {/* Cash on Delivery (COD) Section */}
            <div className={`payment-settings-form__section ${paymentSettings.manualPaymentEnabled.isEnabled ? 'disabled' : ''}`}>
                <h4 className="payment-settings-form__section-title">Cash on Delivery (COD)</h4>
                <div className="payment-settings-form__field">
                    <input
                        type="checkbox"
                        id="CODEnabled"
                        checked={paymentSettings.COD.isEnabled}
                        onChange={(e) => handleCheckboxChange('COD', e.target.checked)}
                        className="payment-settings-form__checkbox"
                    />
                    <label htmlFor="CODEnabled" className="payment-settings-form__label">
                        Enable Cash on Delivery (COD)
                    </label>
                </div>

                {paymentSettings.COD.isEnabled && (
                    <div className="payment-settings-form__field-group">
                        <div className="payment-settings-form__field">
                            <label htmlFor="CODDeliveryCharge" className="payment-settings-form__label">
                                Delivery Charge:
                            </label>
                            <input
                                type="number"
                                id="CODDeliveryCharge"
                                name="deliveryCharge"
                                value={paymentSettings.COD.deliveryCharge}
                                onChange={(e) => handleInputChange(e, 'COD')}
                                className="payment-settings-form__input"
                            />
                        </div>

                        <div className="payment-settings-form__field">
                            <label htmlFor="CODWaiveThreshold" className="payment-settings-form__label">
                                Waive Delivery Charge If Order Value Above:
                            </label>
                            <input
                                type="number"
                                id="CODWaiveThreshold"
                                name="waiveThreshold"
                                value={paymentSettings.COD.waiveThreshold}
                                onChange={(e) => handleInputChange(e, 'COD')}
                                className="payment-settings-form__input"
                            />
                        </div>
                    </div>
                )}
            </div>

            {/* Razorpay Section */}
            <div className={`payment-settings-form__section ${paymentSettings.manualPaymentEnabled.isEnabled ? 'disabled' : ''}`}>
                <h4 className="payment-settings-form__section-title">Razorpay</h4>
                <div className="payment-settings-form__field">
                    <input
                        type="checkbox"
                        id="razorpayEnabled"
                        checked={paymentSettings.razorpay.isEnabled}
                        onChange={(e) => handleCheckboxChange('razorpay', e.target.checked)}
                        className="payment-settings-form__checkbox"
                    />
                    <label htmlFor="razorpayEnabled" className="payment-settings-form__label">
                        Enable Razorpay
                    </label>
                </div>

                {paymentSettings.razorpay.isEnabled && (
                    <div className="payment-settings-form__field-group">
                        <div className="payment-settings-form__field">
                            <label htmlFor="razorpayDeliveryCharge" className="payment-settings-form__label">
                                Delivery Charge:
                            </label>
                            <input
                                type="number"
                                id="razorpayDeliveryCharge"
                                name="deliveryCharge"
                                value={paymentSettings.razorpay.deliveryCharge}
                                onChange={(e) => handleInputChange(e, 'razorpay')}
                                className="payment-settings-form__input"
                            />
                        </div>

                        <div className="payment-settings-form__field">
                            <label htmlFor="razorpayWaiveThreshold" className="payment-settings-form__label">
                                Waive Delivery Charge If Order Value Above:
                            </label>
                            <input
                                type="number"
                                id="razorpayWaiveThreshold"
                                name="waiveThreshold"
                                value={paymentSettings.razorpay.waiveThreshold}
                                onChange={(e) => handleInputChange(e, 'razorpay')}
                                className="payment-settings-form__input"
                            />
                        </div>
                        {/* for test */}
                        
                        {/* <div className="payment-settings-form__field">
                            <button
                                className="payment-settings-form__button"
                                onClick={handleConnectRazorpay}
                            >
                                Connect Razorpay
                            </button>
                        </div> */}
                        {/* <div className="payment-settings-form__field">
                            <button
                                className="payment-settings-form__button"
                                onClick={handlePayNow}
                            >
                                Pay Now
                            </button>
                        </div> */}

                    </div>
                )}
            </div>

            <button onClick={handleSaveSettings} className="payment-settings-form__save-button">
                Save Settings
            </button>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default PaymentSettingsForm;
