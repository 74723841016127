import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import './categoryProductsPage.scss';
import { publicRequest } from '../../../requestMethods';
import ProductCard2 from '../ProductCard-2/ProductCard2';

const CategoryProductsPage = () => {
  const location = useLocation();
  const { categoryId } = useParams();
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [sortOrder, setSortOrder] = useState('default');
  const sellerID = location.pathname.split("/")[1];

  useEffect(() => {
    fetchCategory();
    fetchProducts();
  }, [categoryId, page, selectedCategories, sortOrder]);

  const fetchCategory = async () => {
    const response = await publicRequest.get(`/category/getall/${sellerID}/public`, {
      withCredentials: true,
    });
    console.log("categories", response.data);
    setCategories(response.data);
  };

  const fetchProducts = async () => {
    const response = await publicRequest.get(`/product/category/${sellerID}/public`, {
      withCredentials: true,
      params: {
        categories: selectedCategories.join(','),
        sortOrder: sortOrder,
        page: page,
      }
    });
    console.log("products", response.data);
    setProducts(response.data);
  };

  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    setSelectedCategories(prev => 
      event.target.checked
        ? [...prev, categoryId]
        : prev.filter(id => id !== categoryId)
    );
    setPage(1);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
    setPage(1);
  };

  const loadMoreProducts = () => {
    setPage(prevPage => prevPage + 1);
  };

  return (
    <div className="category-products-page">
    <aside className="side-bar">
      <h3>Categories</h3>
      <ul>
        {categories.map(cat => (
          <li key={cat.id}>
            <label>
              <input
                type="checkbox"
                value={cat.id}
                checked={selectedCategories.includes(cat.id)}
                onChange={handleCategoryChange}
              />
              {cat.name}
            </label>
          </li>
        ))}
      </ul>
    </aside>
    <main className="main-content">
      <nav className="breadcrumb">
        <Link to="/">Home</Link> &gt; {category?.name}
      </nav>
      <div className="header">
        <h1>{category?.name}</h1>
        <select onChange={handleSortChange} value={sortOrder}>
          <option value="default">Default</option>
          <option value="priceAsc">Price: Low to High</option>
          <option value="priceDesc">Price: High to Low</option>
        </select>
      </div>
      <div className="products-grid">
        {products.products && products.products.map(product => (
          <ProductCard2 key={product.id} product={product} />
        ))}
      </div>
      {hasMore && (
        <button onClick={loadMoreProducts} className="load-more">
          Load More
        </button>
      )}
    </main>
  </div>
  );
};

export default CategoryProductsPage;
