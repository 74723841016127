import React, { useEffect, useState } from 'react'
import SingleProductPage from '../../components/store/ProductPage/ProductPage'
import Navbar from '../../components/store/Navbar/Navbar'
import Footer from '../../components/store/Footer/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { publicRequest } from '../../requestMethods'
import CategoryProductsPage from '../../components/store/CategoryProductsPage/CategoryProductsPage'


const CategoryPage = () => {
    const location = useLocation();
    const dispatch = useDispatch()
    const sellerID = location.pathname.split("/")[1];
    const [storeData, setStoreData] = useState()

    useEffect(() => {

        const fetchData = async () => {
            try {
                const storeResponse = await publicRequest.get(`/seller/find/${sellerID}/public`, {
                    withCredentials: true,
                });
                // console.log("Store Data ", storeResponse.data);

                setStoreData(storeResponse.data.store[0])
                if (storeResponse) dispatch(storeData(storeResponse.data[0]));
            } catch (error) {

            }
        }

        fetchData()
    }, [sellerID])


    return (
        <div>
            {storeData && (

                <Navbar
                    navbarData={storeData.navigationBar}
                    sellerID={storeData.storeID}
                />
            )}
           <CategoryProductsPage/>
            {storeData && (

                <Footer footerData={storeData.footer} />
            )}
        </div>
    )
}

export default CategoryPage