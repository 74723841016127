import React from 'react';
import './Pricing.scss';
import FooterHomePage from '../HomePageComponent/FooterHomePage';
import NavbarHomePage from '../HomePageComponent/NavbarHomePage';

const plans = [
  {
    name: 'Free Plan',
    price: '₹0',
    period: '/ forever',
    features: [
      '1 Product',
      '1 Warehouse',
      'Custom shipping options',
    ],
    isPopular: false,
  },
  {
    name: 'Starter',
    price: '₹299',
    period: '/ month',
    features: [
      'Everything in Free Plan',
      '5 Products',
      '2 Warehouses',
    ],
    isPopular: false,
  },
  {
    name: 'Premium',
    price: '₹499',
    period: '/ month',
    features: [
      'Everything in Starter Plan',
      '100 Products',
      'Unlimited Warehouses',
      'Shipping Provider integrations (Delhivery, Shiprocket)',
      'Bulk Order processing',
      'Announcement Bar',
      'Direct Checkout feature',
      'WhatsApp customer support',
      'Order tracking for customers',
    ],
    isPopular: true, // Highlighted as Most Popular
  },
  {
    name: 'Infinity',
    price: '₹999',
    period: '/ month',
    features: [
      'Everything in Premium',
      'Unlimited Products',
      'Custom Domain',
      'Payment Gateway integration',
      'WhatsApp Business API integration',
    ],
    isPopular: false,
  },
];

const Pricing = () => {
  return (
    <>
      <NavbarHomePage />
   
      <div className="pricing">
        <h1 className="pricing__title">Our Subscription Plans</h1>
        <div className="pricing__container">
          {plans.map((plan, index) => (
            <div
              className={`plan-card ${plan.isPopular ? 'plan-card--popular' : ''}`}
              key={index}
            >
              {plan.isPopular && <div className="plan-card__badge">Most Popular</div>}
              <h2 className="plan-card__name">{plan.name}</h2>
              <div className="plan-card__price">
                <span className="plan-card__price-value">{plan.price}</span>
                <span className="plan-card__price-period">{plan.period}</span>
              </div>
              <ul className="plan-card__features">
                {plan.features.map((feature, idx) => (
                  <li key={idx} className="plan-card__feature">
                    {feature}
                  </li>
                ))}
              </ul>
              <button className="plan-card__button" disabled>
                View Only
              </button>
            </div>
          ))}
        </div>
      </div>
      <FooterHomePage/>
    </>
  );
};

export default Pricing;
