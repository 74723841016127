import React from 'react'
import { publicRequest } from '../../../../requestMethods';

const Payment = () => {

    const handlePayNow = async () => {
        console.log("1");

        // Check if Razorpay script is loaded
        if (typeof window.Razorpay === "undefined") {
            console.error("Razorpay script not loaded.");
            return;
        }

        try {
            const response = await publicRequest.post("/payment/createOrder", {
                amount: 100,
                currency: "INR",
                orderId: "Newid",
            });

            console.log("Order from Razorpay", response.data);

            const { order } = response.data;

            return new Promise((resolve, reject) => {
                const options = {
                    key: order.key_id,
                    amount: order.amount,
                    currency: "INR",
                    name: "Prodinent",
                    description: "Product Name",
                    order_id: order.id,
                    handler: function (response) {
                        const paymentId = response.razorpay_payment_id;
                        const orderId = response.razorpay_order_id;
                        const signature = response.razorpay_signature;
                        verifyPayment(paymentId, orderId, signature);
                        resolve(response);
                    },
                    prefill: {
                        name: "Name",
                        email: "noufalva09@gmail.com",
                        contact: 9947672066,
                    },
                    notes: {
                        address: "Prodinent ",
                    },
                    theme: {
                        color: "#3399cc",
                    },
                    notify: {
                        sms: true,
                        email: true,
                    },
                };

                const razorpay = new window.Razorpay(options);
                razorpay.open();

                razorpay.on("payment.failed", function (response) {
                    console.error("Payment failed:", response);
                    reject(response);
                });

            });
        } catch (error) {
            console.log(error);
        }
    }

    const verifyPayment = async (paymentId, orderId, signature) => {
        try {
            // Send payment details to the server for verification
            const result = await publicRequest.post('/payment/verifyPayment', {
                paymentId,
                orderId,
                signature,
            });
            console.log("Result", result.data);


            console.log("Payment verified successfully on the server.");
            // Perform additional actions, such as updating the order status

        } catch (error) {
            console.error("Error verifying payment on the server:", error);
        }
    };
    return (
        <div>
            <button onClick={handlePayNow}>Paynow</button>
        </div>
    )
}

export default Payment