import React, { useState, useEffect, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { sellerRequest } from '../../requestMethods'; // Adjust the path if needed
import { useSelector } from 'react-redux';
import { DateRangePicker } from 'react-date-range';
import { format } from 'date-fns';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './orderReport.scss'; // Adjust path if needed
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
const orderStatusOptions = [
    'Order Placed',
    'Order Confirmed',
    'Order Processing',
    'Manifested',
    'Shipped',
    'Delivered',
    'Cancelled',
    'RTO',
    'LOST',
    'Not Picked'
];





const exportToExcel = (orders) => {
    // Filter the orders data to include only the selected fields


    // Create a worksheet from the filtered orders array
    const worksheet = XLSX.utils.json_to_sheet(orders);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

    // Convert the workbook to a binary string
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Create a blob from the buffer
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Save the file using FileSaver
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = today.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;

    // Save the file using FileSaver with the formatted date
    saveAs(blob, `orders_${formattedDate}.xlsx`);
};

const getFirstAndLastDateOfMonth = (month, year) => {
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    return { startDate: firstDay, endDate: lastDay };
};

const OrderReport = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [dateRange, setDateRange] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }]);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const { sellerID } = useSelector((state) => state.seller.sellerData);

    useEffect(() => {
        const today = new Date();
        const { startDate, endDate } = getFirstAndLastDateOfMonth(today.getMonth(), today.getFullYear());
        setDateRange([{ startDate, endDate, key: 'selection' }]);
    }, []);
    const datePickerRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                setShowDatePicker(false);
            }
        };

        if (showDatePicker) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDatePicker]);


    useEffect(() => {
        const fetchReportData = async () => {
            try {
                const response = await sellerRequest.get(`order/get/${sellerID}`, { withCredentials: true });
                const formattedData = response.data.orders.map(item => ({
                    id: item._id,
                    orderID: item.orderID,
                    customerID: item.customerID,
                    customerName: item.shippingAddress.name,
                    email: item.shippingAddress.email,
                    phone: item.shippingAddress.phone,
                    fullAddress: item.shippingAddress.fullAddress,
                    pincode: item.shippingAddress.pincode,
                    state: item.shippingAddress.state,
                    amount: item.amount,
                    deliveryCharge: item.deliveryCharge,
                    totalOrderValue: item.totalOrderValue,
                    orderDate: new Date(item.createdAt),
                    status: item.status,
                }));
                setData(formattedData);
            } catch (error) {
                console.error('Error fetching report data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchReportData();
    }, [sellerID]);

    const filteredData = data.filter(item => {
        const withinDateRange =
            (!dateRange[0].startDate || item.orderDate >= dateRange[0].startDate) &&
            (!dateRange[0].endDate || item.orderDate <= dateRange[0].endDate);
        const matchesStatus = !selectedStatus || item.status === selectedStatus;
        return withinDateRange && matchesStatus;
    });

    const columns = [
        { field: 'orderID', headerName: 'Order ID', width: 150 },
        { field: 'customerID', headerName: 'Customer ID', width: 150 },
        { field: 'customerName', headerName: 'Customer Name', width: 200 },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'phone', headerName: 'Phone', width: 150 },
        { field: 'fullAddress', headerName: 'Full Address', width: 250 },
        { field: 'pincode', headerName: 'Pincode', width: 100 },
        { field: 'state', headerName: 'State', width: 150 },
        { field: 'amount', headerName: 'Amount', width: 150 },
        { field: 'deliveryCharge', headerName: 'Delivery Charge', width: 180 },
        { field: 'totalOrderValue', headerName: 'Total Order Value', width: 200 },
        { field: 'status', headerName: 'Order Status', width: 200 },
        { field: 'orderDate', headerName: 'Order Date', width: 200, type: 'date' }
    ];

    const [openModal, setOpenModal] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState({});
    const handleOpenModal = () => {
        const initialSelectedColumns = columns.reduce((acc, column) => {
            acc[column.field] = true;
            return acc;
        }, {});
        setSelectedColumns(initialSelectedColumns);
        setOpenModal(true);
    };

    const handleCloseModal = () => setOpenModal(false);

    const handleColumnSelect = (field) => {
        setSelectedColumns(prev => ({ ...prev, [field]: !prev[field] }));
    };

    const exportSelectedColumnsToExcel = () => {
        const filteredOrders = filteredData.map(order => {
            const filteredOrder = {};
            Object.keys(selectedColumns).forEach(field => {
                if (selectedColumns[field]) {
                    filteredOrder[field] = order[field];
                }
            });
            return filteredOrder;
        });

        const worksheet = XLSX.utils.json_to_sheet(filteredOrders);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const today = new Date();
        const formattedDate = format(today, 'dd-MM-yyyy');
        saveAs(blob, `orders_${formattedDate}.xlsx`);

        handleCloseModal();
    };

    const handleSelectAll = () => {
        const allSelected = columns.reduce((acc, column) => {
            acc[column.field] = true;
            return acc;
        }, {});
        setSelectedColumns(allSelected);
    };

    const handleDeselectAll = () => {
        setSelectedColumns({});
    };

    return (
        <div className='orderReport'>
            <h2 className='orderReport__title'>Order Report</h2>

            <div className='orderReport__dateRangeContainer'>
                <div className='orderReport__dateRange'>
                    <span onClick={() => setShowDatePicker(!showDatePicker)} className='orderReport__dateRangeText'>
                        {format(dateRange[0].startDate, 'dd-MM-yyyy')} - {format(dateRange[0].endDate, 'dd-MM-yyyy')}
                    </span>
                    <button
                        className='orderReport__selectRangeBtn'
                        onClick={() => setShowDatePicker(false)}
                    >
                        View
                    </button>
                </div>

                {showDatePicker && (
                    <div className='orderReport__dateRangePickerContainer'>
                        <DateRangePicker
                            onChange={item => setDateRange([item.selection])}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            ranges={dateRange}
                            direction="horizontal"
                        />
                    </div>
                )}
            </div>


            {showDatePicker && (
                <div className='orderReport__dateRangePickerContainer'>
                    <DateRangePicker
                        onChange={item => setDateRange([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        ranges={dateRange}
                        direction="horizontal"
                    />
                </div>
            )}
            <div className="action">
                <div className='orderReport__statusFilter'>
                    <label htmlFor="status-select" className='orderReport__statusLabel'>Order Status:</label>
                    <select
                        id="status-select"
                        className='orderReport__statusSelect'
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                        <option value="">All</option>
                        {orderStatusOptions.map((status) => (
                            <option key={status} value={status}>
                                {status}
                            </option>
                        ))}
                    </select>
                </div>

                <div className='orderReport__exportExcel'>
                    <Tooltip title="Export to Excel" arrow>
                        <button className="export-button" onClick={handleOpenModal}>
                            <DownloadIcon />
                            <span>Export to Excel</span>
                        </button>
                    </Tooltip>
                </div>
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="export-modal-title"
                >
                    <div className="orderReport__exportModal">
                        <h2 id="export-modal-title">Select columns to export</h2>
                        <div className="select-actions">
                            <button onClick={handleSelectAll}>Select All</button>
                            <button onClick={handleDeselectAll}>Deselect All</button>
                        </div>
                        <div className="column-options">
                            {columns.map((column) => (
                                <FormControlLabel
                                    key={column.field}
                                    control={
                                        <Checkbox
                                            checked={selectedColumns[column.field] || false}
                                            onChange={() => handleColumnSelect(column.field)}
                                        />
                                    }
                                    label={column.headerName}
                                />
                            ))}
                        </div>
                        <div className="modal-actions">
                            <button className="export-btn" onClick={exportSelectedColumnsToExcel}>Export Selected</button>
                            <button className="cancel-btn" onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </Modal>
            </div>

            {loading ? (
                <p className='orderReport__loading'>Loading...</p>
            ) : (
                <DataGrid
                    rows={filteredData}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10, 20, 50]}
                />
            )}
        </div>
    );
};

export default OrderReport;
