import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPlus } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Backdrop, CircularProgress, Snackbar, Alert } from '@mui/material';
// Adjust this import path as needed

import './CategoryManagement.scss';
import { uploadImage } from '../../../utils/Firebase';
import { sellerRequest } from '../../../requestMethods';
import { useSelector } from 'react-redux';

const CategoryManagement = () => {
  const { sellerID } = useSelector((state) => state.seller.sellerData);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [newCategory, setNewCategory] = useState({ name: '', description: '', image: '' });
  const [previewImage, setPreviewImage] = useState(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    fetchCategories();
  }, [sellerID]);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await sellerRequest.get(`/category/getall/${sellerID}`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCategorySelect = (event) => {
    const category = categories.find(cat => cat.categoryID === parseInt(event.target.value));
    setSelectedCategory(category);
    console.log(category);
    setPreviewImage(category ? category.image : null);
    setIsAddingNew(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (selectedCategory) {
      setSelectedCategory({ ...selectedCategory, [name]: value });
    } else {
      setNewCategory({ ...newCategory, [name]: value });
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);

      try {
        const path = `Seller Data/${sellerID}/Category Images`;
        const url = await uploadImage(file, path);
        console.log("Image uploaded successfully. URL:", url);

        // Update the category with the new image URL
        if (selectedCategory) {
          setSelectedCategory({ ...selectedCategory, image: url });
        } else {
          setNewCategory({ ...newCategory, image: url });
        }

        console.log("Image uploaded successfully. URL:", url);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (selectedCategory) {
        await sellerRequest.put(`/category/${sellerID}/${selectedCategory.categoryID}`, {
          name: selectedCategory.name,
          description: selectedCategory.description,
          image: selectedCategory.image
        });
        setSnackbarMessage('Category updated successfully');
      } else {
        await sellerRequest.post(`/category/${sellerID}`, { ...newCategory, sellerID });
        setSnackbarMessage('Category added successfully');
      }
      fetchCategories();
      setSelectedCategory(null);
      setNewCategory({ name: '', description: '', image: '' });
      setPreviewImage(null);
      setIsAddingNew(false);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving category:', error);
      setSnackbarMessage('Error saving category');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!selectedCategory) return;
    setLoading(true);
    try {
      await sellerRequest.delete(`/category/${sellerID}/${selectedCategory.categoryID}`);
      fetchCategories();
      setSelectedCategory(null);
      setPreviewImage(null);
    } catch (error) {
      console.error('Error deleting category:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddNew = () => {
    setIsAddingNew(true);
    setSelectedCategory(null);
    setNewCategory({ name: '', description: '', image: '' });
    setPreviewImage(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div className="category-management">
      <div className="category-management__header">
        <h2 className="category-management__title">Category Management</h2>
        <button
          className="category-management__add-button"
          onClick={handleAddNew}
        >
          <FaPlus />
        </button>
      </div>
      <select
        className="category-management__select"
        value={selectedCategory ? selectedCategory.categoryID : ''}
        onChange={handleCategorySelect}
      >
        <option value="" disabled>Select a category</option>
        {categories.map((category) => (
          <option key={category.categoryID} value={category.categoryID}>
            {category.name}
          </option>
        ))}
      </select>
      {(selectedCategory || isAddingNew) && (
        <div className="category-management__form">
          <input
            className="category-management__input"
            type="text"
            placeholder="Name"
            name="name"
            value={selectedCategory ? selectedCategory.name : newCategory.name}
            onChange={handleInputChange}
          />
          <input
            className="category-management__input"
            type="text"
            placeholder="Description"
            name="description"
            value={selectedCategory ? selectedCategory.description : newCategory.description}
            onChange={handleInputChange}
          />
          <div className="category-management__image-upload">
            <input
              className="category-management__image-upload-input"
              accept="image/*"
              type="file"
              onChange={handleImageUpload}
              id="image-upload"
            />
            <label htmlFor="image-upload" className="category-management__image-upload-label">
              <FontAwesomeIcon icon={faUpload} />
              {previewImage ? ' Change Image' : ' Upload Image'}
            </label>
            <div className="category-management__image-preview">
              {previewImage ? (
                <img
                  src={previewImage}
                  alt="Category Preview"
                  className="category-management__image-preview-img"
                />
              ) : (
                <PhotoCameraIcon className="category-management__image-preview-icon" />
              )}
            </div>
          </div>
          <div className="category-management__actions">
            <button
              onClick={handleSubmit}
              className="category-management__button category-management__button--primary"
            >
              {selectedCategory ? 'Update' : 'Save'} Category
            </button>
            {selectedCategory && (
              <button
                onClick={handleDelete}
                className="category-management__button category-management__button--secondary"
              >
                Delete Category
              </button>
            )}
          </div>
        </div>
      )}
      {loading && (
        <Backdrop
          open
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CategoryManagement;