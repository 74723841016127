import { sellerRequest } from "../../requestMethods";

export const deleteOrder = async (orderData, sellerID, setLoading, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen, handleBack) => {
  const isConfirmed = window.confirm('Are you sure you want to delete this order?');
  if (!isConfirmed) return;

  try {
    const res = await sellerRequest.delete(`/order/${sellerID}/${orderData._id}`, {
      withCredentials: true
    });
    setLoading(false);
    setSnackbarMessage('Order deleted successfully!');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    handleBack();
  } catch (error) {
    setLoading(false);
    setSnackbarMessage('Failed to delete order!');
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  }
};

export const cancelOrder = async (orderData, sellerID, setOrderData, setLoading, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen) => {
  const isConfirmed = window.confirm('Are you sure you want to cancel this order?');
  if (!isConfirmed) return;

  setOrderData(prevOrderData => ({
    ...prevOrderData,
    status: 'Cancelled',
  }));
  const updatedOrderData = { ...orderData, status: "Cancelled" };
  try {
    const response = await sellerRequest.put(`/order/status/${sellerID}/${orderData._id}`, updatedOrderData);
    setLoading(false);
    setSnackbarMessage('Order Cancelled!');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  } catch (error) {
    setLoading(false);
    setSnackbarMessage('Failed to cancel order!');
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  }
};

export const duplicateOrder = async (orderData, sellerID, setLoading, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen) => {
  const duplicatedOrder = {
    ...orderData,
    _id: undefined,
    orderID: '',
    status: 'Order Placed',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    waybill: '',
    shippingCost: '',
  };

  try {
    setLoading(true);
    const response = await sellerRequest.post(`/order`, duplicatedOrder, {
      withCredentials: true
    });
    // console.log('Duplicated order created', response.data);
    setLoading(false);
    setSnackbarMessage(`Order duplicated successfully!   Order ID:${response.data.orderID}`);
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  } catch (error) {
    console.error('Error duplicating order:', error);
    setLoading(false);
    setSnackbarMessage('Failed to duplicate order!');
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  }
};

export const updateOrderStatus = async (orderData, sellerID, newStatus, selectedWarehouseID, selectedShippingService, setLoading, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen, setOrderData, setWarehouseWarning) => {
  // console.log("Shipping service", selectedShippingService);
  // console.log(`Updating order status to ${newStatus}`);

  if (!selectedShippingService) {
    setWarehouseWarning('Select a shipping service');
    return;
  }

  if (!selectedWarehouseID && newStatus === "Order Confirmed") {
    setWarehouseWarning('Select a warehouse');
    return;
  }

  setLoading(true);

  try {
    const updatedOrderData = {
      ...orderData,
      status: newStatus,
      warehouseID: selectedWarehouseID,
      shippingService: selectedShippingService
    };
    const response = await sellerRequest.put(`/order/status/${sellerID}/${orderData._id}`, updatedOrderData);
    // console.log(response);
    setOrderData(prevOrderData => ({
      ...prevOrderData,
      status: newStatus,
      warehouseID: selectedWarehouseID,
      shippingService: selectedShippingService
    }));

    setLoading(false);
    setSnackbarMessage(`Order status updated to ${newStatus} successfully!`);
    setSnackbarSeverity('success');
  } catch (error) {
    console.error('Request error:', error);
    setLoading(false);
    setSnackbarMessage(`Failed to update order status to ${newStatus}!`);
    setSnackbarSeverity('error');
  } finally {
    setSnackbarOpen(true);
  }
};

export const updateOrderTotalWeight = async (orderData, sellerID, packageDetails, setLoading, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen, setOrderData) => {
  // console.log("Updating order total weight to ", packageDetails);

  setLoading(true);

  try {
    const updatedOrderData = {
      ...orderData,
      shipment: {
        length: packageDetails.length,
        breadth: packageDetails.breadth,
        height: packageDetails.height,
        totalWeight: packageDetails.weight,
      },
    };
    const response = await sellerRequest.put(`/order/status/${sellerID}/${orderData._id}`, updatedOrderData);
    // console.log(response);
    setOrderData(prevOrderData => ({
      ...prevOrderData,
      shipment: {
        ...orderData.shipment,
        totalWeight: packageDetails.weight,
      },
    }));

    setLoading(false);
    setSnackbarMessage(`Order total weight updated to ${packageDetails.weight} successfully!`);
    setSnackbarSeverity('success');
  } catch (error) {
    console.error('Request error:', error);
    setLoading(false);
    setSnackbarMessage(`Failed to update order total weight to ${packageDetails.weight}!`);
    setSnackbarSeverity('error');
  } finally {
    setSnackbarOpen(true);
  }
};
