import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyB5SXQxbE-trpx8s6KkkKtZ0HSZK1FNPGY",
    authDomain: "prodinent-fc68e.firebaseapp.com",
    projectId: "prodinent-fc68e",
    storageBucket: "prodinent-fc68e.appspot.com",
    messagingSenderId: "1058117669608",
    appId: "1:1058117669608:web:d0e465a17dcd83bd3f6f5d",
    measurementId: "G-48VWHMJ3E8"
  };


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const metadata = {
    contentType: 'image/jpeg'
};
export const uploadImage = (file, path) => {
    // console.log("file",file);
    // console.log("path",path);
    return new Promise((resolve, reject) => {
        const storageRef = ref(storage, `${path}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file,metadata);

        uploadTask.on('state_changed',
            (snapshot) => {
                // Progress function ...
            },
            (error) => {
                // Error function ...
                reject(error);
            },
            () => {
                // Complete function ...
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    resolve(downloadURL);
                });
            }
        );
    });
};