import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './MainContent.scss';
import ImageProduct from './Graphics/Asset2.svg';
import Store_pic from './Graphics/Store.svg';
import Shippinglabel_pic from './Graphics/Shippinglabel.svg';
import StoreFront_pic from './Graphics/StoreFront.svg';
import Campaign_pic from './Graphics/Campaign.svg';
import Dashboard_pic from './Graphics/Dashboard.svg';
import OrderFlow_pic from './Graphics/OrderFlow.svg';

const MainContent = () => {
  const featuresRef = useRef([]);
  
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Trigger when 50% of the element is visible
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    featuresRef.current.forEach(feature => {
      observer.observe(feature);
    });

    return () => {
      featuresRef.current.forEach(feature => {
        observer.unobserve(feature);
      });
    };
  }, []);

  const addToRef = (el) => {
    if (el && !featuresRef.current.includes(el)) {
      featuresRef.current.push(el);
    }
  };

  const [storeName, setStoreName] = useState('');
  const navigate = useNavigate();
  // const handleChange = (e) => {
  //   setStoreName(e.target.value);
  // };

  const handleChange = (e) => {
    const value = e.target.value.toLowerCase().replace(/[^a-z0-9-]/g, ''); // Convert to lowercase and allow only lowercase letters, numbers, and hyphens
    setStoreName(value);
  };
  const handleClick = () => {
    if (storeName.trim()) {
      navigate(`/register?storeID=${encodeURIComponent(storeName.trim())}`);
    } else {
      navigate('/register');
    }
  };

  return (
    <div className="main-content">
      <div className="main-content-hero">
        <h1 className="main-content-hero__title">Launch your store in seconds</h1>
        <div className="main-content-hero__input-group">
          <span className="main-content-hero__prefix">Prodinent.com/</span>
          <input
            type="text"
            className="main-content-hero__input"
            value={storeName}
            onChange={handleChange}
            placeholder="storename"
          />
          <button className="main-content-hero__button" onClick={handleClick}>
            Get Started
          </button>
        </div>
        <p className="main-content-hero__offer-label">It's free for the first three months!</p>
      </div>

      <div className="main-content-feature" ref={addToRef}>
        <div className="main-content-feature__text">
          <h2>Launch Your E-Commerce Store in a Few Clicks</h2>
          <p>Create and customize your online store effortlessly, optimized for all devices.</p>
        </div>
        <img src={StoreFront_pic} alt="List Products" className="main-content-feature__image" />
      </div>

      <div className="main-content-feature--reverse" ref={addToRef}>
        <div className="main-content-feature__text">
          <h2>Quickly Showcase Your Products</h2>
          <p>Easily add and manage your products with our user-friendly interface.</p>
        </div>
        <img src={Store_pic} alt="List Products" className="main-content-feature__image" />
      </div>

      <div className="main-content-feature" ref={addToRef}>
        <div className="main-content-feature__text">
          <h2>Manage Your Orders and Print Shipping Labels</h2>
          <p>Generate and print labels directly from portal, eliminating the need for manual entry and saving time. Focus on your business while we streamline your shipping process.</p>
        </div>
        <img src={Shippinglabel_pic} alt="List Products" className="main-content-feature__image" />
      </div>

      <div className="main-content-feature--reverse" ref={addToRef}>
        <div className="main-content-feature__text">
          <h2>Analytics at Your Fingertips</h2>
          <p>Monitor your store’s performance with detailed analytics and insights.</p>
        </div>
        <img src={Dashboard_pic} alt="List Products" className="main-content-feature__image" />
      </div>

      <div className="main-content-feature" ref={addToRef}>
        <div className="main-content-feature__text">
          <h2>Effortless Order Collection</h2>
          <p>Streamline your social media sales by sharing a link for customers to collect their shipping details, seamlessly syncing with your order dashboard.</p>
        </div>
        <img src={OrderFlow_pic} alt="List Products" className="main-content-feature__image" />
      </div>

      <div className="main-content-feature--reverse" ref={addToRef}>
        <div className="main-content-feature__text">
          <h2>Reach Your Customers Again</h2>
          <p>Engage existing customers with targeted marketing campaigns.</p>
        </div>
        <img src={Campaign_pic} alt="List Products" className="main-content-feature__image" />
      </div>
    </div>
  );
};

export default MainContent;
