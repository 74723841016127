import React from 'react'
import './navbar.scss'
import { Link } from 'react-router-dom'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { useDispatch, useSelector } from 'react-redux';
import Badge from '@mui/material/Badge';
import { changeCartVisibility } from '../../../redux/settingsRedux';
import Cart from '../Cart/Cart';
import ShoppingBag from '@mui/icons-material/ShoppingBag';
import { uploadImage } from '../../../utils/Firebase';
const Navbar = ({ navbarData, sellerID }) => {

    const quantity = useSelector(state => state.cart.products.length)
    const showCart = useSelector(state => state.settings.showCart)
    const link = sellerID;
    const cartProducts = useSelector(state => state.cart.products);
    const sellerCartItems = cartProducts.filter(product => product.sellerID === sellerID);

    // Get the number of unique items (not based on quantity)
    const sellerUniqueItemCount = sellerCartItems.length;

    // console.log("Navbar data", navbarData);
    // console.log("seller ID", sellerID);
    // const logoSrc = store?.logo ? store.logo : 'https://firebasestorage.googleapis.com/v0/b/cartify-0246.appspot.com/o/Test%2FScreenshot%202024-05-18%20001339.png?alt=media&token=73acfe5f-c566-4e8c-8eda-2500552484af';
    const dispatch = useDispatch()

    if (!navbarData) {
        return null;
    }

    return (
        <div className='navbar' style={{ backgroundColor: navbarData.backgroundColor }}>
            <div className="left">
                <Link to={`/${link}`}>
                    {navbarData?.logo && <img src={navbarData.logo} alt="Store Logo" />}
                </Link>
            </div>
            <div className="right">
                <Badge badgeContent={sellerUniqueItemCount} color="secondary" onClick={() => {
                    dispatch(changeCartVisibility(!showCart));
                }}>
                    <ShoppingBagIcon />
                </Badge>
                <Cart />
            </div>
        </div>
    )
}

export default Navbar