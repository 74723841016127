import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "settings",
    initialState: {
        loading:false,
        showCart: false,

    },
    reducers: {
        changeCartVisibility: (state, action) => {
            state.showCart = action.payload
        },
        startProcess: (state, action) => {
            state.showCart = action.payload
        },
        finishProcess: (state, action) => {
            state.showCart = action.payload
        },


    },
});

export const { changeCartVisibility,startProcess,finishProcess } = cartSlice.actions;
export default cartSlice.reducer;
