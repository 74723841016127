import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './forgotPassword.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars } from 'react-loader-spinner';
import { publicRequest } from '../../requestMethods';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true);
    setLoading(true);
    
    try {
      const response = await publicRequest.post('/auth/forgot-password', { email });
      setSuccessMessage('Password reset link sent to your email.');
      setLoading(false);
    } catch (error) {
      setErrorMessage('Failed to send password reset link. Please try again.');
      setLoading(false);
    }
    setSubmit(false);
  };

  return (
    <div className='forgot-password'>
      <div className='forgot-password__container'>
        <div className='forgot-password__content'>
          <h2 className='forgot-password__title'>Forgot Password</h2>
          <form className='forgot-password__form' onSubmit={handleSubmit}>
            <div className='forgot-password__form-group'>
              <label htmlFor='email' className='forgot-password__label'>Email</label>
              <input
                type='email'
                id='email'
                className='forgot-password__input'
                placeholder='Enter your email'
                value={email}
                onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
                required
              />
            </div>
            <button type='submit' className='forgot-password__button'>
              {submit ? (
                <Bars
                  heigth='30'
                  width='30'
                  color='#0b2b66'
                  ariaLabel='loading'
                  className='forgot-password__loader'
                />
              ) : (
                'Send Reset Link'
              )}
            </button>
          </form>
          {successMessage && (
            <div className='forgot-password__success-message'>
              {successMessage}
            </div>
          )}
          {errorMessage && (
            <div className='forgot-password__error-message'>
              {errorMessage}
            </div>
          )}
          <ToastContainer />
        </div>
        <div className='forgot-password__back'>
          <a onClick={() => navigate('/login')}>Back to Login</a>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
