import React, { useState, useEffect } from 'react';
import './offlineOverlay.scss';

function OfflineOverlay() {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (!isOffline) return null;

  return (
    <div className="offlineOverlay">
      <div className="offlineOverlay__message">
        <p>You are offline. Please check your internet connection.</p>
      </div>
    </div>
  );
}

export default OfflineOverlay;
