import React from 'react';
import NavbarHomePage from './HomePageComponent/NavbarHomePage';
import MainContent from './HomePageComponent/MainContent';
import AnimatedImages from './HomePageComponent/AnimatedImages';
import FooterHomePage from './HomePageComponent/FooterHomePage';
import './homePage.scss';

const HomePage = () => {
  return (
    <div className="homepage">
      <NavbarHomePage />
      <MainContent />
      <FooterHomePage/>
      {/* <AnimatedImages /> */}
    </div>
  );
}

export default HomePage;
