import React from 'react';
import './footer.scss';

const Footer = ({ footerData }) => {
  if (!footerData) {
    return null;
  }
  const { aboutUs, contactUs, followUs } = footerData;
  const { backgroundColor, headingColor, contentColor } = footerData.colorSettings;

  const footerStyle = {
    backgroundColor: backgroundColor,
    color: contentColor,
  };

  const headingStyle = {
    color: headingColor,
  };


  const socialLinkStyle = {
    color: contentColor,
  };

  return (
    <footer className="footer" style={footerStyle}>
      <div className="footer-container">
        {aboutUs.isActive && (
          <div className="footer-section">
            <h4 style={headingStyle}>About Us</h4>
            <p>{aboutUs.value}</p>
          </div>
        )}

        {contactUs.isActive && (
          <div className="footer-section">
            <h4 style={headingStyle}>Contact Us</h4>
            <p>Email: {contactUs.email}</p>
            <p>Phone: {contactUs.mobile}</p>
          </div>
        )}

        {followUs.isActive && (
          <div className="footer-section">
            <h4 style={headingStyle}>Follow Us</h4>
            <div className="social-links">
              {followUs.socialMedia.map((social, index) => (
                <a
                  key={index}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={socialLinkStyle}
                >
                  {social.name}
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
      {/* <div className="footer-bottom">
        <p>&copy; 2024 Online Store. All rights reserved.</p>
      </div> */}
    </footer>
  );
};

export default Footer;
