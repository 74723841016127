import { createSlice } from "@reduxjs/toolkit";
const userSlice = createSlice({
  name: "seller",
  initialState: {
    sellerData: null,
    accessToken: null,
    isFetching: false,
    error: false,

  },

  reducers: {
    loginStart: (state) => {
      state.isFetching = true;
      state.error = false;

    },
    loginSuccess: (state, action) => {
      // console.log("Login success working",action.payload.sellerData);
      state.isFetching = false;
      state.accessToken = action.payload.sellerData.accessToken;
      state.sellerData = action.payload.sellerData;

    },

    loginFailure: (state) => {
      state.isFetching = false;
      state.error = true;

    },
    logout: (state) => {
      state.isFetching = false;
      state.sellerData = null;
      state.accessToken =null;

    },
    updateSellerData: (state, action) => {
      // console.log("REDUX UPDATE");
      state.sellerData = action.payload.sellerData;
    },

  },
});

export const { loginStart, loginFailure, loginSuccess, logout, updateSellerData } = userSlice.actions;
export default userSlice.reducer;

