import React from 'react';
import './TermsConditions.scss';
import FooterHomePage from '../HomePageComponent/FooterHomePage';
import NavbarHomePage from '../HomePageComponent/NavbarHomePage';

const TermsConditions = () => {
  return (
    <>
      <NavbarHomePage />
      
      <div className="terms-conditions">
        <h1 className="terms-conditions__title">Terms & Conditions</h1>
        <div className="terms-conditions__content">
          <h2>Introduction</h2>
          <p>
            Welcome to Prodinent! These Terms & Conditions govern your use of our website, www.prodinent.com, and any services provided through it. By using our website and services, you agree to comply with and be bound by these Terms & Conditions. Please read them carefully before using our services.
          </p>
          
          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing and using our website, you acknowledge that you have read, understood, and agree to be bound by these Terms & Conditions. If you do not agree with any part of these terms, you must not use our website or services.
          </p>
          
          <h2>2. Changes to Terms</h2>
          <p>
            We reserve the right to modify or update these Terms & Conditions at any time without prior notice. Your continued use of our website and services following any changes constitutes your acceptance of the new terms. 
          </p>
          <p><strong>Effective Date:</strong> These Terms & Conditions are effective as of 11.09.2024.</p>
          
          <h2>3. Use of the Website</h2>
          <p>
            You agree to use our website in accordance with all applicable laws and regulations. You must not use the website in any way that could damage, disable, overburden, or impair it, or interfere with any other party's use and enjoyment of the website.
          </p>

          <h2>4. User Restrictions</h2>
          <p>
            Users are restricted from:
          </p>
          <ul>
            <li>Uploading or transmitting any malicious code, viruses, or harmful content.</li>
            <li>Attempting to gain unauthorized access to our systems, data, or services.</li>
            <li>Using our website for illegal purposes or to engage in any fraudulent activity.</li>
            <li>Posting any content that is harmful, offensive, or infringes upon the rights of others.</li>
          </ul>
          
          <h2>5. Rules of Conduct</h2>
          <p>
            By using Prodinent, you agree to:
          </p>
          <ul>
            <li>Provide accurate and current information when creating an account.</li>
            <li>Be responsible for maintaining the confidentiality of your account information and password.</li>
            <li>Notify us immediately of any unauthorized use of your account.</li>
            <li>Comply with all local and international laws applicable to your use of the platform.</li>
          </ul>

          <h2>6. Intellectual Property</h2>
          <p>
            All content on our website, including text, graphics, logos, and images, is the property of Prodinent or its licensors and is protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from any content without our prior written consent.
          </p>
          
          <h2>7. Termination</h2>
          <p>
            We reserve the right to terminate or suspend your access to our website and services at our sole discretion, without notice, for conduct that we believe violates these Terms & Conditions or is harmful to other users or the website.
          </p>
          
          <h2>8. Limitation of Liability and Disclaimer of Warranties</h2>
          <p>
            To the fullest extent permitted by law, Prodinent and its affiliates, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from or related to your use of our website or services.
          </p>
          <p>
            All services are provided "as is" without any warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not guarantee that our services will be uninterrupted, error-free, or completely secure.
          </p>
          
          <h2>9. Contact Information</h2>
          <p>
            For any questions regarding our Terms & Conditions or any other inquiries, please contact us at:
          </p>
          <p>
            <strong>Email:</strong> hi@prodinent.com<br />
            <strong>Phone:</strong> +91 8943697432<br />
            <strong>Address:</strong> Hyder Manzil, Velupadam Post, Thrissur, Kerala, 680303
          </p>
          
          <p className="terms-conditions__last-updated">
            Last updated: 11.09.2024
          </p>
        </div>
      </div>

      <FooterHomePage />
    </>
  );
};

export default TermsConditions;
