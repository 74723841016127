// StoreSettingsForm.js
import React from 'react';

const StoreSettingsForm = ({ storeData, handleInputChange, handleUpdate }) => {
  return (
    <div className="store-settings-form">
      <div className="store-settings-form__group">
        <h3 className="store-settings-form__label">Store Name</h3>
        <input
          className="store-settings-form__input"
          type="text"
          id="storeName"
          name="storeName"
          value={storeData.storeName}
          onChange={handleInputChange}
        />
      </div>
      <div className="store-settings-form__group">
        <h3 className="store-settings-form__label">Store About</h3>
        <textarea
          className="store-settings-form__input"
          id="storeAbout"
          name="storeAbout"
          value={storeData.storeAbout}
          onChange={handleInputChange}
        />
      </div>
      <div className="store-settings-form__group">
        <h3 className="store-settings-form__label">GST</h3>
        <input
          className="store-settings-form__input"
          type="text"
          id="gst"
          name="gst_tin"
          value={storeData.gst_tin}
          onChange={handleInputChange}
        />
      </div>
      <button className="store-settings-form__update-button" onClick={handleUpdate}>
        Update
      </button>
    </div>
  );
};

export default StoreSettingsForm;
