import React from 'react';
import './CancellationRefundPolicy.scss';
import FooterHomePage from '../HomePageComponent/FooterHomePage';
import NavbarHomePage from '../HomePageComponent/NavbarHomePage';

const CancellationRefundPolicy = () => {
  return (
    <>
      <NavbarHomePage />
      
      <div className="cancellation-refund-policy">
        <h1 className="cancellation-refund-policy__title">Cancellation/Refund Policy</h1>
        <div className="cancellation-refund-policy__content">
          <h2>1. Subscription Cancellation Policy</h2>
          <p>
            As a SaaS platform, we offer subscription-based services to sellers for listing and managing their online stores. Sellers can subscribe to our platform on a monthly basis.
          </p>
          <p>
            <strong>Subscription Cancellation:</strong> Sellers may cancel or pause their subscription at any time through the seller dashboard. Once canceled, the subscription will remain active until the end of the current billing cycle, after which access to premium features will be revoked.
          </p>
          <p>
            <strong>Refunds for Subscriptions:</strong> Subscription fees are non-refundable once the billing cycle has begun. No refunds will be provided for partial use of the service within a billing cycle.
          </p>

          <h2>2. Seller-Specific Refund and Cancellation Policies</h2>
          <p>
            Each seller on our platform operates their own store and establishes independent return, refund, and cancellation policies for their products. As such, the following guidelines apply:
          </p>
          <p>
            <strong>Order Cancellations and Refunds:</strong> Customers must follow the specific refund and cancellation policies set by individual sellers when placing orders. These policies will be displayed on each seller’s store page. Prodinent does not process or manage product-related refunds or cancellations on behalf of sellers.
          </p>
          <p>
            <strong>Seller Support:</strong> If you have any issues with a product purchased through one of the seller stores, please contact the seller directly according to their provided contact information.
          </p>

          <h2>3. Contact Us</h2>
          <p>
            For any questions related to your subscription with Prodinent or assistance with the platform, please contact us at:
          </p>
          <p>
            <strong>Email:</strong> hi@prodinent.com<br />
            <strong>Phone:</strong> 919947410204<br />
            <strong>Address:</strong> Hyder Manzil Velupadam Post, Thrissur, Kerala, 680303
          </p>
          <p className="cancellation-refund-policy__last-updated">
            Last updated: 11.09.2024
          </p>
        </div>
      </div>

      <FooterHomePage />
    </>
  );
};

export default CancellationRefundPolicy;
