import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid'; // Example library for displaying data tables
import { sellerRequest } from '../../requestMethods';
import { useSelector } from 'react-redux';

const TransactionReport = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { sellerID } = useSelector((state) => state.seller.sellerData);
    useEffect(() => {
        const fetchReportData = async () => {
            try {
                const response = await sellerRequest.get(`/reports/transaction-report/${sellerID}`, {
                    params: { sellerID: 'your-seller-id' } // Adjust as needed
                });
                console.log("Report",response.data);
                const formattedData = response.data.map(item => ({
                    id: item._id, // Assuming _id is unique
                    orderID: item.orderID,
                    paymentMethod: item.paymentMethod,
                    paymentStatus: item.paymentStatus,
                    amount: item.amount,
                    paymentDate: new Date(item.paymentDate).toLocaleDateString() // Format as needed
                }));
                setData(formattedData);
            } catch (error) {
                console.error('Error fetching report data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchReportData();
    }, []);

    const columns = [
        { field: 'orderID', headerName: 'Order ID', width: 150 },
        { field: 'paymentMethod', headerName: 'Payment Method', width: 180 },
        { field: 'paymentStatus', headerName: 'Payment Status', width: 180 },
        { field: 'amount', headerName: 'Amount', width: 150 },
        { field: 'paymentDate', headerName: 'Payment Date', width: 200 },
        // Add more columns as needed
    ];

    return (
        <div style={{ height: 400, width: '100%', marginTop: '50px' }}>
            <h2>Transaction Report</h2>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <DataGrid rows={data} columns={columns} pageSize={10} />
            )}
        </div>
    );
};

export default TransactionReport;
