// components/Layout/Layout.js
import React, { useState } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import { Outlet } from 'react-router-dom'; // Import Outlet
import './layout.scss'; // Adjust or create this CSS file as needed

const Layout = () => {
    const [inactive, setInactive] = useState(true);

    return (
        <div className='layout'>
            <Sidebar onCollapse={(inactive) => setInactive(inactive)} />
            <div className={`layout__container ${inactive ? 'inactive' : ''}`}>
                <Navbar />
                <div className="layout__container-content">
                    <Outlet /> {/* This will render nested routes */}
                </div>
            </div>
        </div>
    );
};

export default Layout;
