import React from 'react';
import './NavbarHomePage.scss';
import { useNavigate } from 'react-router-dom';
const NavbarHomePage = () => {

  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };
  const handleSignupClick = () => {
    navigate('/register');
  };
  return (
    <nav className="navbarHomepage">
      <div className="navbarHomepage__logo">Prodinent</div>
      <div className="navbarHomepage__auth-buttons">
        <button className="navbarHomepage__button-login" onClick={handleLoginClick}>Login</button>
        <button className="navbarHomepage__button-signup" onClick={handleSignupClick}>Signup</button>
      </div>
      
    </nav>
  );
}

export default NavbarHomePage;
